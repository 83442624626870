/* Toast ui viewer font 관련 설정 */

.toastui-editor-contents * {
  font-family: "SpoqaHanSansNeo", "NotoSansCJKkr", "Noto Sans KR";
  font-size: 13px;
}

.projectDetail .toastui-editor-contents * {
  font-size: 16px;
  line-height: 28px;
  color: #333333;
}

.toastui-editor-contents ol li::before {
  color: #333333 !important;
  text-align: center !important;
}

.toastui-editor-contents ul li::before {
  background-color: #333333 !important;
  margin-top: 10px !important;
}

.toastui-editor-contents img {
  width: 100%;
}
